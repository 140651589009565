import React from 'react';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '8deb2830c2ad450fb796c9c87483c9eb',
  environment: 'production',
  server: {
    root: "https://app.dietarymenu.com/",
    branch: "main",
  },
};

export default function Rollbar({children}) {
  if (process.env.NODE_ENV !== 'development') {
    return (
      <ErrorBoundary config={rollbarConfig}>
        <RollbarProvider config={rollbarConfig}>
          {children}
        </RollbarProvider>
      </ErrorBoundary>
    );
  } else {
    return children;
  }
}
