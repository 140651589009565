import './Option.scss';
import React from 'react';
import { connect } from 'react-redux';
import { humanizeRequirement, toggleRequirement } from '../../../../store/requirements';

const Option = ({requirement, icon, active, onClick}) => {
  const title = humanizeRequirement(requirement);

  return <div className="Option" onClick={onClick}>
    <div className={`icon ${active ? 'active' : ''}`}>
      <img src={icon} alt=""/>
    </div>

    <p>{title}</p>
  </div>;
};

const mapStateToProps = (state, props) => (
  {
    active: state[props.requirement],
  }
);

const mapDispatchToProps = (dispatch, props) => (
  {
    onClick: () => dispatch(toggleRequirement(props.requirement)),
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Option);

