import React from 'react';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import MasterScene from './scenes/Master';
import './App.scss';
import Rollbar from "./components/Rollbar";

function App() {
  return <Rollbar>
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={HomeComponent}/>
            <Route exact path="/:slug" component={MasterScene}/>
            <Route path="*"><Redirect to={'/'}/></Route>
          </Switch>
        </div>
      </Router>
    </Provider>
  </Rollbar>;
}

// Used for LoadBalancer
function HomeComponent() {
  return (
    <div>
      <p>Digestible Tech - Home</p>
      <ul>
        <li>
          <Link to="/stefans-cafe-stratford">Cafe</Link>
        </li>
      </ul>
    </div>
  );
}

export default App;
