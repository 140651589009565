import React from 'react';
import { connect } from 'react-redux';
import MenuKey from './components/MenuKey';
import MenuSection from './components/MenuSection';
import { humanizeRequirement } from '../../store/requirements';
import './Menu.scss';

const Menu = ({menus, branch, requirements, navigate}) => {
  const [showUnsuitable, setShowUnsuitable] = React.useState(false);

  const filteredMenus = () => {
    return menus.filter(m => m.visible);
  };

  const editSelections = () => navigate('requirements');

  const toggleUnsuitable = () => setShowUnsuitable(!showUnsuitable);

  const renderMenus = () => {
    const visibleMenus = filteredMenus();

    return visibleMenus.sort((a, b) => a.sorting_order - b.sorting_order).map((m, i) => <MenuSection
      openDefault={visibleMenus.length === 1}
      branchId={branch.id}
      key={i}
      menu={m}
      requirements={requirements}
      showUnsuitable={showUnsuitable}
    />);
  };

  const renderNoMenus = () => {
    return <div className={"selections text-center"}>
      <p>No menus available for this branch.</p>
    </div>;
  };

  const renderToggle = () => {
    return <div className="toggle">
      <p>Show Unsuitable</p>

      <div
        className={`outer ${showUnsuitable ? 'active' : 'inactive'}`}
        onClick={() => setShowUnsuitable(!showUnsuitable)}
      >
        <div className="inner"/>
      </div>
    </div>;
  };

  const renderSelections = () => {
    return <div className="selections">
      <div className="upper">
        <h2>Your dietary requirements/allergens:</h2>
        <p className="edit" onClick={editSelections}>Edit</p>
      </div>

      <div className="lower">
        {
          requirements.length > 0
            ? requirements.map(s => <p key={s}>{humanizeRequirement(s)}</p>)
            : <span className={"none-selected"}>None selected</span>
        }
      </div>
    </div>;
  };

  const visibleMenus = filteredMenus();
  let disclaimer;
  if (branch.secondary_disclaimer) {
    disclaimer = <p className="warning">{branch.secondary_disclaimer}</p>;
  }

  return <div className="scene Menu">
    <MenuKey
      showUnsuitable={showUnsuitable}
      toggleUnsuitable={toggleUnsuitable}
    />

    <div className="inner">
      {renderSelections()}
      {disclaimer}
      {renderToggle()}
      {visibleMenus.length > 0 ? renderMenus() : renderNoMenus()}

      <div className="footer">
        <p>Powered by <a href="https://dietarymenu.com" target="blank">Dietary Menu</a></p>
      </div>
    </div>
  </div>;
};

const mapStateToProps = (state, props) => (
  {
    requirements: Object.keys(state).filter(k => state[k]),
  }
);

const mapDispatchToProps = (dispatch, props) => (
  {}
);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
