import React from 'react';
import * as Options from './components/Option';
import './Requirements.scss';
import { connect } from 'react-redux';

const RequirementsScene = ({branch, requirements, navigate}) => {
  const next = () => {
    window.posthog.capture("customer_select_allergens", {
      branch: branch,
      requirements: requirements,
    });
    navigate('menu');
  };

  return <div className="RequirementsScene scene">
    <div className="header">
      <h2>Dietary Requirements</h2>
      <p>Please select your dietary requirements below</p>
    </div>

    <div className="card iam">
      <h3>I am...</h3>
      <div className="options-list">
        {Options.Vegetarian}
        {Options.Vegan}
        {/* { Options.GlutenFree } */}
        <div className="Option" style={{minWidth: 90}}/>
      </div>
    </div>

    <div className="card">
      <h3>I am allergic to...</h3>
      <div className="options-list allergens">
        {Options.NutAllergy}
        {Options.PeanutsAllergy}
        {Options.FishAllergy}
        {Options.EggsAllergy}
        {Options.CrustaceansAllergy}
        {Options.MolluscsAllergy}
        {Options.MilkAllergy}
        {Options.SoyaAllergy}
        {Options.CeleryAllergy}
        {Options.MustardAllergy}
        {Options.LupinAllergy}
        {Options.SesameSeedsAllergy}
        {Options.SulphiteAllergy}
        {Options.CerealsWithGlutenAllergy}
        <div className="Option" style={{minWidth: 90}}/>
      </div>
      <button onClick={next} className="primary continue">Continue</button>
    </div>
  </div>;
};

const mapStateToProps = (state, props) => (
  {
    requirements: state,
  }
);

export default connect(mapStateToProps)(RequirementsScene);