import React from 'react';
import { axios } from "../../utilities/axios";
import MenuScene from '../Menu';
import DisclaimerScene from '../Disclaimer';
import RequirementsScene from '../Requirements';

export default function Master({match}) {
  const {slug} = match.params;
  const [scene, setScene] = React.useState('loading');
  const [branch, setBranch] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchBranch = async () => {
      try {
        const res = await axios.get('/api/public/v1/branches/' + slug);
        setBranch(res.data);
        setScene('disclaimer');
      } catch (err) {
        const errorMessage = err.response.status === 404 ? 'Branch Not Found' : 'Unknown';
        setError(errorMessage);
        setScene('error');
      }
    };
    fetchBranch();
  }, [slug]);

  const navigate = React.useCallback(newScene => {
    window.scrollTo({top: 0});
    setScene(newScene);
  }, []);

  switch (scene) {
    case 'loading':
      return <div/>;
    case 'disclaimer':
      return <DisclaimerScene branch={branch} navigate={navigate}/>;
    case 'requirements':
      return <RequirementsScene branch={branch} navigate={navigate}/>;
    case 'menu':
      return <MenuScene branch={branch} menus={branch.menus} navigate={navigate}/>;
    default:
      return <p>{error}</p>;
  }
}
